@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.container-change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 350px;

  .change-password-heading {
    color: $primary-theme-color;
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .change-password-button {
    margin-top: 50px;
    width: 150px;
    float: right;
    background-color: $primary-theme-color !important;
  }
  .btn-secondary {
    border-color: inherit !important;
  }
  .btn {
    border-radius: 0px !important;
    padding: 5px !important;
  }
  .input-form {
    outline: 0 !important;
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid $primary-theme-color !important;
    border-radius: 0 !important;
    box-sizing: content-box;
    background-color: transparent !important;
    background: transparent !important;
    margin-bottom: 25px;
  }
  .label {
    margin-bottom: 0px !important;
  }
  .eye-icon {
    position: absolute;
    left: 325px;
  }
  ::placeholder {
    color: $primary-theme-color;
  }

  label {
    display: flex !important;
    position: relative;
  }

  .password-rule {
    font-size: 12px;
  }
  .password-rule-error {
    font-size: 12px;
    color: $danger;
  }
  .error-msg {
    color: $danger;
    font-size: 12px;
    margin-bottom: 0px !important;
  }
}
