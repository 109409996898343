@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

$filter-min-width: 200px;

.filter-contain {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: flex-end;
}
.filterList {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.filter {
  min-width: $filter-min-width;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
}
.time-filter {
  min-width: $filter-min-width;
  margin-left: 10px;
  margin-right: 10px;
}

.filter-handle {
  font-size: $medium-font-size;
  font-weight: $medium-font-weight;
  margin-left: 20px;
}
.filter-name {
  font-weight: $primary-font-weight;
  color: $filter-name-color;
}

.lower-div {
  display: flex;
  margin-top: 20px;
}
.middle-area {
  margin-left: 20px;
  margin-right: 20px;
}
