@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.carriage-first-bogie-bg-img {
  background-image: url("../../../assets/images/backgroundImageTrain.png");
  height: 90px;
  width: 150px;
  background-repeat: no-repeat;
  position: relative;
  margin-left: 14px;
  margin-right: 9px;
}

.carriage-first-bogie-bg-img-sharing {
  background-image: url("../../../assets/images/backgroundImageTrain.png");
  height: 90px;
  width: 150px;
  background-repeat: no-repeat;
  position: relative;
  margin-left: -82px;
}

.key-style {
  font-size: 24px;
  color: $train-wagon-text;
  font-weight: $bold-font-weight;
  font-family: $primary-font-family;
}

.wheelSets-container {
  display: flex;
}
