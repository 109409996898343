@import "../../assets//bootstrap//color.scss";
@import "../../assets//bootstrap//font.scss";

.map {
  color: $white;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 2px $primary-card-shadow;
  background-color: $white;
  height: 100%;
  .col {
    height: 90%;
    min-height: 90% !important;
  }
}

.map-container {
  background-color: $white;
  max-width: 100%;
  height: 90%;
}

.map-row {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.map-button:hover {
  background-color: $primary-theme-color !important ;
}

.map-disable-button {
  background-color: $white !important;
  color: black !important;
  font-family: $primary-font-family !important;
  margin: 3px;
  size: 14px;
}

.map-button {
  background-color: $primary-theme-color !important;
  color: white !important;

  font-family: $primary-font-family !important;
  margin: 3px;
  size: 14px;
}

.map-container-top-div {
  z-index: 1;
  position: absolute;
  padding-left: 20px;
  top: 106px;
  max-width: 90%;
}

.actions-div {
  background-color: $danger;
  border-radius: 20px;
  color: $white;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.marginInfoFleet {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
  padding-top: 10px;
}
.fleetInfoMapTitle {
  font-weight: bold;
  color: $train-status-color;
  font-size: 12px;
}
.gradient-container {
  width: 90% !important;
  .col-12 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.col-12 {
  .col-6 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.gradient {
  background-image: linear-gradient(
    to right,
    #50e3c2,
    #0f58d6,
    #053589,
    indigo
  );
  height: 20px;
}
.gradient-low-label {
  text-align: left;
  color: $train-status-color;
  font-size: 12px;
}
.gradient-high-label {
  text-align: right;
  color: $train-status-color;
  font-size: 12px;
}
