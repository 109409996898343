$primary-theme-color: #0f58d6; //background color for dashboard
$error-theme-color: red;
$progress-theme-weak: #f25f5c;
$progress-theme-fair: #ffe066;
$progress-theme-good: #247ba0;
$progress-theme-strong: #70c1b3;
$white: white;
$primary-theme-text: #737578;
$primary-background-theme: #efeff4;
$color_checkbox_success: rgb(0, 119, 255);
$color_checkbox_default: #8e8e93;
$black: black;
$primary-theme-background: #f2f2f2;
$primary-card-shadow: #e6e6e6;
$danger: #f0133e;
$warning: #ffcc00;
$primary-active-theme: #0e4bb6; //background color for active icons on dashboard
$hover-background-theme: #3f69d2; //background color hover icons for dashboard
$danger-theme-color: #cc0000; //warning color for train wagon
$warning-theme-color: #ffc914; //danger color for train wagon
$filter-name-color: #343a40; //color for filter
$border-bottom: #313a42; //bottom-border for filters;
$train-wagon-background: #ebf0f0; //background for train wagon
$train-wagon-text: #b3b9bb; //background for train color;
$train-status-color: #676f7c; //color for train status
$train-wagon-border: #9ea0a2; //border for train wagon
$primary-background-theme-fleet: #f6f6f6; //background for fleet page
$primary-status-heading: #363c42;
$status-text: #343a40;
$dropdown-bottom-border-color: #dde2e6;
$dropdown-default-text: #6c757d;
$card-asset-lower-text: #656f7d;
$primary-border-right: #dddddd;
$blue-charcol: #212529;
$solitude: #ebecee;
$dark-grey: #d8d8d8;
$dodger-blue: #1e90ff;
$vivid-orange: #ffc914;
$primary-active-theme: rgba(0, 0, 0, 0.15);
$hover-background-theme: #3f69d2;
$text-color: #808895; //for breadcrumbs and dropdown in vehicledetail page
$whisper-grey: #eae8e8;
$light-slate-grey: #808895;
$drop-down-item-border-down: #00000029;
$category-list-folder: #343a40;
$category-table-border: #dde2e6;
$light-grey: #cdcdcd;
$feedback-table-content-color: #343a40;
