@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";
.list-items {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.active-list-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $primary-active-theme;
}
.icon {
  vertical-align: 100%;
}
.headings {
  align-self: center;
  margin-bottom: 0px;
  line-height: 0px;
  color: $white;
  margin-left: 5px;
  font-size: $small-font-size;
  font-weight: $bold-font-weight;
}
a:hover {
  background-color: $hover-background-theme;
  text-decoration: none !important;
}
