@import "../../../../assets/bootstrap/color.scss";
@import "../../../../assets/bootstrap/font.scss";

.wagon-container.wagon {
  width: 160px;
  height: 75px;
  background-color: $train-wagon-background;
  border-radius: 5px;
  border: solid $train-wagon-border;
  border-bottom-color: transparent;
  border-width: 1px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
}
.wagon-container.focusRightBorder {
  width: 160px;
  height: 75px;
  background-color: $train-wagon-background;
  border-radius: 5px;
  border: solid $train-wagon-border;
  border-bottom-color: transparent;
  border-width: 1px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-right-radius: 90px;
  border-bottom-right-radius: 25px;
  position: relative;
  overflow: hidden;
}
.wagon-container.focusLeftBorder {
  width: 160px;
  height: 75px;
  background-color: $train-wagon-background;
  border-radius: 5px;
  border: solid $train-wagon-border;
  border-width: 1px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top-left-radius: 90px;
  border-bottom-left-radius: 25px;
  position: relative;
  overflow: hidden;
}
.wagon-container {
  .wagon-tools {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    height: 24px;
  }
  .key-for-wagon {
    margin-bottom: 0px;
    font-weight: $secondary-font-weight;
    font-size: $large-font-size;
    color: $train-wagon-text;
  }
  .status-div {
    color: $train-status-color;
    font-size: $micro-font-size;
  }
  .upper-wagon {
    display: flex;
    justify-content: center;
    width: inherit;
  }
  .color-section {
    height: 5px;
    background-color: $train-wagon-border;
  }
  .tool-kit {
    display: flex;
  }
  .danger-bg-color {
    background-color: $danger-theme-color;
  }
  .warning-bg-color {
    background-color: $warning-theme-color;
  }
  .None-bg-color {
    background-color: inherit;
  }
}
