@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.document-table {
  margin-top: 25px;
  display: flex;
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 20px;

  thead th {
    border-top: 0px !important;
    border-bottom: 1px solid $category-table-border !important;
  }
  tr:nth-child(even) {
    background-color: $primary-background-theme-fleet;
  }
  .table-head-content {
    font-weight: bold !important;
    color: $feedback-table-content-color !important;
    width: 14%;
  }
  tr {
    font-size: 14px;
    color: $feedback-table-content-color;
  }

  .download-image {
    padding-left: 25px;
    cursor: pointer;
  }

  .no-table-data {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    height: 400px;
  }
}
