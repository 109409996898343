@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.chartjs-width {
  display: inline !important;
}

.canvas-container {
  background-color: white;
  margin-bottom: 10px;
  margin-right: 10px;

  canvas {
    background-color: white;
    height: 210px !important;
    width: 100% !important;
    max-width: 100%;
  }

  .temp-heading-graph {
    display: flex;
    padding: 0px 20px;
    padding-top: 10px;
  }

  .sub-text {
    font-size: 12px;
    font-family: $primary-font-family;
  }
}
