@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.modal {
  .modal-title {
    color: $white !important;
  }
  .modal-header {
    background-color: $primary-theme-color !important;
    color: $primary-theme-color;
  }
  .modal-body-content {
    display: flex;
    flex-direction: column;
    padding: 25px !important;
  }
  .upper-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .train-name {
    display: flex;
  }
  .train-key {
    font-size: 16px;
  }
  .train-value {
    font-size: 16px;
    font-weight: bold;
  }
}
