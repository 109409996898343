@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.train-slider-container {
  &.train {
    // overflow: hidden !important;
    display: block !important;
    // padding: 0px 10px;
  }
  margin: 0px 10px;
  .slick-disabled {
    display: none !important;
  }

  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
  .slider-next-arrow {
    width: 7px;
    height: 11px;
    &.left-arrow {
      transform: rotate(180deg);
    }
  }
  .slick-track {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.fleet-train-parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  .train-details {
    display: flex;
    font-size: $micro-font-size;
  }
  .train {
    display: flex;
    margin-top: 10px;
  }
  .fleet-train {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 12px;
  }
  .top {
    margin-left: 10px;
  }
  .detail-link {
    margin-left: 20px;
  }
  .icon-forward {
    margin-left: 5px;
  }
  .train-name {
    margin-right: 20px;
  }
  a:hover {
    background-color: inherit;
    color: #007bff !important;
  }
}
.no-trains-div {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
