@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
body {
  height: 100%;
}
.dasboard-parent-div {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  .dashboard-asset {
    background-color: $primary-background-theme-fleet;
    flex-grow: 1;
    height: 100%;
    padding-bottom: 55px;
    .col-sm-12 {
      min-height: 100% !important;
    }
  }

  .left-asset-div {
    min-width: 350px;
    justify-content: center;
  }

  .row {
    display: flex;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .filter-div {
    background-color: #c7c5c5;
    border-radius: 14px;
    padding: 5px;
    margin: 10px;
    font-size: 12px;
    font-family: $primary-font-family;
    font-weight: $bold-font-weight;
    white-space: nowrap;
    width: 150px;
    height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .active-filters-div {
    display: flex;
    flex-wrap: wrap;
  }
  .filter-main-div {
    background-color: $primary-background-theme-fleet;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .status-filter-div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .clear-filters-div {
    font-family: $primary-font-family;
    color: $dodger-blue;
    font-size: $micro-font-size;
  }
  a:hover {
    background-color: inherit !important;
  }

  .chip {
    display: flex;
    align-items: center;
    padding: 0 25px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    height: 40px;

    border-radius: 25px;
    background-color: $whisper-grey;
    max-width: 200px;
  }

  .ellipses-div {
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $micro-font-size;
    font-weight: $bold-font-weight;
    font-family: $primary-font-family;
  }
  .close-image:hover {
    cursor: pointer;
  }
}
