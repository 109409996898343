@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.pagination {
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  button {
    margin-right: 14px;
    background-color: transparent;
    border: 1px solid $dropdown-bottom-border-color;
  }
  button:hover {
    cursor: pointer;
  }
  :disabled:hover {
    cursor: default;
  }
}
.pagination .active {
  background-color: $primary-theme-color;
  color: $white;
}

.pagination a:hover {
  background-color: #ddd !important;
}
.pagination a:hover.active {
  background-color: $primary-theme-color !important;
}
