@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.trains-table-container {
  background-color: $white;
  box-shadow: 2px 2px 10px 2px $primary-card-shadow;
  padding: 10px;
  border-radius: 4px;
  flex-wrap: wrap;
  margin-top: 20px;
  flex: 1;
}

.table-heading {
  font-size: 16px;
  font-weight: $bold-font-weight;

  font-family: $primary-font-family;
}
