@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.header-div {
  .space-middle {
    height: 100px;
  }
  .header {
    background-color: $primary-theme-color;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    color: $white;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .logo-class {
    height: 18px;
  }
  .header-links {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  .profile-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .profile-image {
    height: 22px;
    width: 22px;
    border-radius: 15px;
  }

  .header-sections {
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .heading-text {
    align-self: center;
    margin-bottom: 0px;
    line-height: 0px;
    color: $white;
    margin-left: 10px;
    font-size: $medium-font-size;
  }
  .vertical-line {
    height: 20px;
    background-color: $white;
    width: 1.5px;
    align-self: center;
    margin-right: 15px;
    margin-left: 25px;
  }
  .nav-link {
    padding: 0px !important;
    background-color: inherit !important;
  }
  .list-of-links {
    display: flex;
    flex-wrap: wrap;
  }

  .dropdown-menu {
    top: 15px !important;
    transform: translate3d(-120px, 25px, 0px) !important;
    height: 94vh;
    font-size: 14px !important;
    @media (max-width: 1440px) {
      height: 92.5vh;
    }
  }
  .dropdown-item {
    padding: 12px !important;
    text-align: right !important;
    border-bottom: 1px solid $drop-down-item-border-down !important;
  }
  .seperate-background-color {
    background-color: $primary-background-theme-fleet;
  }
  .seperate-background-color-last {
    sposition: fixed;
    bottom: 0;
    border-top: 1px solid $drop-down-item-border-down !important;
  }
  .togglebutton {
    display: none;
  }
  .mobile-nav {
    display: none;
  }
  @media (max-width: 950px) {
    .list-of-links {
      display: none;
    }
    .togglebutton {
      display: block;
      align-self: center;
      padding: 5px;
    }
    .mobile-nav {
      display: block;
      background-color: $primary-theme-color;
    }
    .mobile-button {
      background-color: inherit !important;
      border-color: $black;
    }
  }
}
