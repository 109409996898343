@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.modal {
  .modal-backdrop.show {
    // opacity: 0.1 !important;
  }

  .modal-footer {
    border-top: 0px !important;
  }

  .modal-title {
    font-size: 25px;
    color: $white;
    line-height: 0px;
    align-content: center;
    align-self: center;
  }
  .feedback-modal-header {
    background-color: $primary-theme-color;
  }

  .modal-body {
    padding: 0px !important;
  }

  .event-status {
    display: flex;
    justify-content: space-between;
  }
  .feedback-breadcrumb {
    align-self: center;
  }

  .event-info {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0px 15px 20px;
  }
  .action-status-container {
    display: flex;
    flex-direction: column;
  }
  .heading-div {
    font-size: 12px;
    font-weight: bold;
  }
  .heading-div-light {
    color: $light-slate-grey;
    font-size: 12px;
  }

  .event-case {
    display: flex;
    padding: 5px 20px 5px 20px;
    align-items: center;
  }
  .event-case-key {
    font-size: 16px;
    color: $black;
    padding-right: 5px;
  }
  .event-case-value {
    font-size: 16px;
    color: $black;
    font-weight: bold;
  }
  .action-status-div {
    display: flex;
    background-color: $primary-background-theme-fleet;
    padding: 22px 22px 22px 20px;
  }
  .modified-by-container {
    margin-left: 30px;
  }
  .modified-by {
    font-size: 12px;
    margin-top: 5px;
  }

  .horizontal-divider {
    border: 1px solid light-grey;
  }
  hr {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .success-class {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
  }
  .failure-class {
    display: flex;
    color: $danger;
    font-size: 10px;
    justify-content: center;
    align-items: center;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
  }
  .btn-secondary {
    color: black;
    color: $white;
    background-color: $primary-theme-color;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // color: $black !important;
    // background-color: $white !important;
    // border-color: transparent !important;
    // border-bottom: 1px solid $black !important;
    border-radius: 0px !important;
    padding: 0px !important;
    padding-top: 10px !important;
  }
  .btn:focus {
    box-shadow: none !important;
  }
  // .btn-secondary.dropdown-toggle:focus {
  //   box-shadow: transparent !important;
  // }
  .save-button {
    width: 140px !important;
    padding: 5px !important;
  }
  .save-button-loading {
    display: flex;
  }
  .cancel-button {
    padding: 5px !important;
    background-color: $white;
    border-color: #b0b5bd;
    color: #b0b5bd;
    width: 140px;
  }

  .padding {
    padding: 10px;
  }

  .display-flex {
    display: flex;
    flex-direction: column;
  }
  .preview-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    margin-left: 10px;
  }
  .preview-heading {
    font-size: 12px;
    color: $black;
    font-weight: bold;
  }
  .preview-images {
    display: flex;
    flex-wrap: wrap;
  }
  .preview-image-name {
    font-size: 8px;
    color: $black;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
  }
  .feedback-modal-footer {
    font-size: 13px;
    padding: 20px;
    color: $primary-theme-color;
    cursor: pointer;
  }

  .preview {
    width: 100px;
    display: flex;
    margin: 10px 10px 0px 10px;
    flex-direction: column;
  }
  p {
    margin-bottom: 5px !important;
  }
}
