@import "../../../../assets/bootstrap/color.scss";
@import "../../../../assets/bootstrap/font.scss";

.traction {
  width: 20px;
  height: 25px;
  position: absolute;
}

.front {
  .traction-absent {
    top: 24px;
    left: 30px;
    @extend .traction;
  }

  .traction-red {
    background-image: url("../../../../assets/images/redTractionMotor.svg");
    @extend .traction-absent;
  }
  .traction-amber {
    background-image: url("../../../../assets/images/amberTractionMotor.svg");
    @extend .traction-absent;
  }

  .traction-none {
    background-image: url("../../../../assets/images/greyTractionMotor.svg");
    @extend .traction-absent;
  }
}

.back {
  .traction-absent {
    top: 43px;
    @extend .traction;
  }
  .traction-red {
    background-image: url("../../../../assets/images/redTractionMotor.svg");
    @extend .traction-absent;
  }

  .traction-amber {
    background-image: url("../../../../assets/images/amberTractionMotor.svg");
    @extend .traction-absent;
  }

  .traction-none {
    background-image: url("../../../../assets/images/greyTractionMotor.svg");
    @extend .traction-absent;
  }
}

.disable {
  opacity: 0;
}
