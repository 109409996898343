@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.feedback-dropdown {
  .action-status {
    width: 180px !important;
  }
  .other-status {
    width: 150px;
  }

  // width: 23px;
  .btn-secondary {
    display: flex;
    font-size: 12px !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $black !important;
    background-color: inherit !important;
    border-color: $black;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }
}
