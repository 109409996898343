@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.table-body-parent-container {
  .table-header {
    background-color: $primary-card-shadow;
  }

  .table-container {
    padding-top: 8px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }

  .action-left {
    justify-content: center;
    display: flex;
  }

  .style-font {
    font-size: $micro-font-size;
    font-family: $primary-font-family;
    font-weight: $bold-font-weight;
  }
}
