$primary-font-weight: 700;
$secondary-font-weight: 900;
$medium-font-weight: 500;
$least-font-weight: 300;
$medium-font-size: 18px;
$large-font-size: 20px;
$medium-heading-font-size: 16px;
$primary-font-family: Arial;
$small-font-size: 14px;
$micro-font-size: 12px;
$bold-font-weight: bold;
