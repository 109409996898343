@import "../../../assets/bootstrap/font.scss";
@import "../../../assets/bootstrap/color.scss";

.abc-test {
  label {
    pointer-events: none;
    color: gray;
    cursor: not-allowed;
    // .checkbox-item {

    // }
  }
}

.dropdown-filter-container {
  input {
    margin-top: 0 !important;
    background-color: transparent !important;
    border-bottom: 0px !important;
  }

  .filter-name {
    margin-bottom: 0px;
    font-weight: $primary-font-weight;
    color: $filter-name-color;
  }
  input {
    width: inherit !important;
  }
  a {
    border: 0px !important;
    border-bottom: 1px solid $border-bottom !important;
  }
  a:hover {
    background-color: inherit !important;
  }
  .toggle:after {
    content: url(../../../assets/images/arrowUp.svg);
  }

  .toggle.collapsed:after {
    content: url(../../../assets/images/DownArrowNew.svg);

    margin-left: 2px;
    margin-bottom: 0px;
    margin-top: 2px;
  }
  .toggle.expanded:after {
    content: url(../../../assets/images/arrowDown.svg);
    margin-top: 10px !important;
  }
  .checkbox-item {
    // position: relative;
    // width: 0.8rem !important;
    // height: 0.8rem;
    // margin-right: 0.5rem;
    // cursor: pointer;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // appearance: none;
    // outline: 0;
    // vertical-align: middle;
    // background-color: $primary-theme-color !important;
    // color: blue !important;
  }

  .checkbox-item:before {
    // content: "";
    // position: absolute;
    // left: 0;
    // top: 0;
    // z-index: 1;
    // width: 100%;
    // height: 100%;
    // border-radius: 50%;
  }

  // label:hover .checkbox-item {
  //   border-radius: 50% !important;
  //   box-shadow: 1px -1px 1px 1px $primary-theme-color !important;
  // }

  // .checkbox-item:checked:before {
  //   // display: block;
  //   // border-color: $primary-theme-color;
  //   // background-color: $primary-theme-color !important;
  //   // color: blue !important;
  // }
  // .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  //   content: url(../../../assets/images/arrowDropDown.svg);
  //   vertical-align: middle;
  //   // color: #3c3c3c;
  //   margin-right: 2px;
  // }
  // .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  //   content: url(../../../assets/images/arrowDropUp.svg);
  //   vertical-align: middle;
  //   // color: #3c3c3c;
  //   margin-right: 2px;
  // }
}
