@import "../../assets/bootstrap/font.scss";
@import "../../assets/bootstrap/color.scss";

.train-top-view {
  margin-left: 10px;
  margin-right: 10px;
  display: block;
  background-color: $white;
  .slick-list {
    margin-top: -30px;
    padding-top: 30px;
  }
  .slick-disabled {
    display: none !important;
  }
  .slick-arrow {
    top: 45%;
    &::before {
      color: $primary-theme-color;
    }
  }
  .slider-next-arrow {
    width: 7px;
    height: 11px;
    &.left-arrow {
      transform: rotate(180deg);
    }
  }
  .slick-track {
    margin-left: 0px;
    margin-right: 0px;
  }
}
