@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";
.drop-down-parent {
  margin-right: 20px;
  .toggle {
    width: 100%;
    border: 0px;
    border-bottom: solid 1px $dropdown-bottom-border-color;
    font-size: 16px;
    vertical-align: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: $primary-font-family;
    color: $dropdown-default-text;
    font-weight: $primary-font-weight;
    background-color: $white;
  }

  .dropdownItem {
    font-size: 16px;
    font-family: $primary-font-family;
  }
}
