@import "../../assets/bootstrap/color.scss";

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spinner-a {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
  &::before {
    animation: 2s linear infinite spinner;
    border: solid 3px #eee;
    border-bottom-color: $primary-theme-color;
    border-radius: 50%;
    content: "";
    height: 40px;
    width: 40px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    will-change: transform;
  }
}

.spinner-b {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
  &::before {
    animation: 2s linear infinite spinner;
    border: solid 3px #eee;
    border-bottom-color: $primary-theme-color;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    will-change: transform;
  }
}

.spinner-button {
  @extend .spinner-b;
  height: 25px !important;
}

.spinner-main-app {
  @extend .spinner-a;

  height: 100vh;
}
.spinner-category-modal {
  @extend .spinner-a;
  height: 350px;
}
.spinner-events-feedback-modal {
  @extend .spinner-a;
  height: 50px;
}
