@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.report-parent {
  .report-body {
    background-color: $solitude;
    border-width: 1.5px;
  }

  .report-rest {
    padding-left: 40px;
  }

  .report-filter {
    // margin: 10px;
  }
  .lower-report-tables {
    padding-top: 40px;
  }
}
