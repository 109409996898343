@import "../../../assets/bootstrap/color.scss";

.date-picker {
  margin-top: 0px !important;

  width: 100px !important;
  border: 0px !important;
  border-bottom: solid 1px $primary-theme-text !important;
  height: 15px !important;
  background-color: inherit;
}

.date-pickers-div {
  width: 100px;
}
