@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";
.preview-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  margin-left: 10px;
}
.preview-images {
  display: flex;
  flex-wrap: wrap;
}
.preview {
  width: 100px;
  display: flex;
  margin: 10px 10px 0px 10px;
  flex-direction: column;
  a:hover {
    background-color: transparent;
  }
}
