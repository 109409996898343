@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

$transition: 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$width: 30px;
$height: 17px;
$height_circle: $width/2.5;

.display {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // position: absolute;
  // right: 20px;

  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.toggle {
  .toggle-control {
    transition: $transition;
    width: $width;
    height: $height;
    display: block;
    border: 2px solid $color_checkbox_default;
    border-radius: $height;
    background-color: rgba(black, 0.06);
    position: relative;
    &:after {
      transition: $transition;
      content: "";
      width: $width/2;
      height: $height_circle;
      display: block;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(black, 0.4), 0 3px 2px rgba(black, 0.4);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  input {
    display: none;
    &:checked + .toggle-control {
      border-color: $color_checkbox_success;
      background-color: $color_checkbox_success;
      &:after {
        left: $width/2;
      }
    }
  }

  .toggle_input {
  }
}
.filter-handle {
  font-size: $micro-font-size !important;
  margin-top: -15px;
  margin-left: 5px !important;
}
