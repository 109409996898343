@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";
.parent-breadcrumb {
  .breadcrumb {
    background-color: transparent;
  }
  a {
    background-color: inherit !important;
    font-size: $micro-font-size !important;
    font-weight: $least-font-weight !important;
    color: $text-color !important;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
  }

  .active {
    color: $status-text !important;
    font-weight: bold !important;
  }
}
