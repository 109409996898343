@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.count-style-left {
  font-size: 45px;
  font-weight: $primary-font-weight;
}

.count-style-right {
  font-size: 45px;
  font-weight: $primary-font-weight;
}

.default-component {
}

.danger {
  color: $danger;
}

.warning {
  color: $warning;
}
.default {
  color: $train-status-color;
}

.icon-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.number-count-container {
  display: flex;
}
