@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.graph-container {
  // .temp-graph {
  //   background-color: $white;
  //   margin-top: 10px;
  // }
  .no-data-div {
    height: 175px;
    font-weight: $bold-font-weight;
    font-size: $medium-heading-font-size;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  path {
    fill: none;
    stroke: black;
    stroke-width: 1;
  }

  .dot {
    fill: #0054de;
    stroke: #fff;
  }

  .grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
  }

  .vibration-speed-graph {
    background-color: white;
    margin-top: 10px;
  }

  .heading-text {
    font-size: 12px;
    color: $blue-charcol;
    font-family: $primary-font-family;
    font-weight: $bold-font-weight;
  }

  .temp-heading-graph {
    display: flex;
    padding: 20px;
  }

  .vibration-speed-heading-graph {
    display: flex;
    padding: 10px;
  }

  .sub-text {
    font-size: 12px;
    font-family: $primary-font-family;
  }

  .tooltip {
    // background-color: $blue-charcol;
  }
}
