@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.previous-comments {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-right: 30px;
  padding-left: 30px;

  .previous-comments-heading {
    color: $black;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .previous-feedback-table {
    display: flex;
    max-height: 250px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .table-header {
    background-color: $primary-background-theme-fleet;
  }
  tr:nth-child(even) {
    background-color: $primary-background-theme-fleet;
  }

  .table-head-content {
    font-size: 12px !important;
    font-weight: bold !important;
    color: $feedback-table-content-color !important;
    width: 14%;
  }

  .table-head-content-comment {
    font-size: 12px !important;

    color: $feedback-table-content-color !important;
    width: 30%;
  }

  tr {
    font-size: 12px;
    color: $feedback-table-content-color;
  }

  th {
    border-bottom: 0px !important;
    font-weight: bold !important;
    font-size: 12px;
  }
  .table-content {
    display: contents;
  }
  .images-button {
    background-color: $primary-theme-color;
    color: white;
    border: none;
    border-radius: 5px;
    outline: none;
  }
  .no-images-button {
    opacity: 0.5;
    @extend .images-button;
  }
  .images-display-button {
    cursor: pointer;
    @extend .images-button;
  }
  .display-error {
    display: flex;
    justify-content: center;
  }
}
