@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  align-self: center;
  width: 150px;
  .btn {
    color: $white;
    background-color: $primary-theme-color;
    font-size: 12px;
    border-radius: 0px !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  img {
    vertical-align: none !important;
    background-color: $primary-theme-color;
  }

  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .paper-clip {
    transform: rotate(45deg);
  }
}
