@import "../../../assets/bootstrap/font.scss";
@import "../../../assets/bootstrap/color.scss";

.category-dropdown-container {
  display: flex;
  align-items: center;

  .category-dropdown-heading {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 10px;
  }
  .category-dropdown {
    width: 200px;
  }
  .btn-secondary {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black !important;
    background-color: $white !important;
    border-color: transparent !important;
    border-bottom: 1px solid $black !important;
  }
  .btn:focus {
    box-shadow: none !important;
  }
  .btn-secondary.dropdown-toggle:focus {
    box-shadow: transparent !important;
  }
}
