@import "../../assets/bootstrap/font.scss";
@import "../../assets/bootstrap/color.scss";

.report-container {
  padding-top: 30px;
  overflow-y: scroll;
  .reports-status-container {
    display: flex;
  }
  .report-body-status-bar {
    display: flex;
  }

  .report-side-danger-hightlighter {
    background-color: $danger;
    width: 4px;
  }

  .report-side-amber-hightlighter {
    background-color: $warning;
    width: 4px;
  }

  .report-status-bar {
    background-color: $primary-theme-background;
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .status-header {
    display: flex;
  }

  .report-table-header {
    background-color: $primary-background-theme-fleet;
    font-size: 12px;
    color: $blue-charcol;
  }

  .report-table-head-danger-side-highlighter {
    padding: 0px !important;
    background-color: $danger;
    width: 4px;
    height: 50px;
    display: flex;
    border-bottom: 0px !important;
    border-top: 0px !important;
  }

  .report-table-head-amber-side-highlighter {
    padding: 0px !important;
    background-color: $warning;
    width: 4px;
    height: 50px;
    display: flex;
    border-bottom: 0px !important;
    border-top: 0px !important;
  }
}

.report-container,
.modal {
  .report-breadcrumb-div {
    align-self: center;
    font-size: 12px;
    margin-right: 20px;
    color: #808895;
  }
}

.report-body-container {
  background-color: $white;
  margin: 10px;
  padding: 20px;
  border-radius: 3px;
}

.status-bar-content {
  margin: 20px;
  margin-right: 152px;
}

.heading {
  color: #808895;
  font-size: 14px;
}

.value {
  color: $status-text;
  font-family: $primary-font-family;
  font-weight: $bold-font-weight;
}

.align-text {
  text-align: center;
}

.td-text {
  color: $blue-charcol;
  font-size: 12px;
  font-family: $primary-font-family;
}

.table-cell-width {
  width: 14%;
}

.table-cell-width-recommendation {
  width: 30%;
}

.btn-style {
  display: flex;
  justify-content: center;
}

.td-text-recommended-actions {
  max-width: 150px;
  color: $blue-charcol;
  font-size: 12px;
  font-family: $primary-font-family;
}
.table td {
  border-top: 0px !important;
}

.right-arrow-reports {
  // margin-left: 20px;
}

.right-arrow-reports {
  display: flex;
  cursor: pointer;
}

.arrow-div {
  margin-left: 10px;
  align-self: center;
}

.report-table-row-side-yellow-highlighter {
  padding: 0px !important;
  background-color: $vivid-orange;
  width: 4px;
  border-top: 0px !important;
}

.report-table-row-side-red-highlighter {
  padding: 0px !important;
  background-color: $danger;
  width: 4px;
  border-top: 0px !important;
}

.recommendation {
  display: flex;
  margin-top: 5px;
}

.max-width {
  max-width: 60%;
}

.collapse-visible {
  visibility: visible;
}

.collapse-collapse {
  visibility: collapse;
}
