@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.select-container {
  span {
    display: none;
  }
}

.filter-name {
  margin-bottom: 0px;
  font-weight: $primary-font-weight;
  color: $filter-name-color;
}
// .react-select__indicators {
//   display: flex !important;
// }
// .react-select-container {
//   display: flex !important;
// }
// .link-section {
//   width: 100%;
//   border: 0px;
//   border-bottom: solid 1px $primary-theme-text;
//   font-size: 12px;
//   vertical-align: 0px;
//   background-color: $primary-background-theme;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
