@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.card-container {
  background-color: $white;
  box-shadow: 2px 2px 10px 2px #d9d9d9;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 125px;
  height: 140px;
  border-color: $white;
  border-radius: 4px;
  flex-wrap: wrap;
  margin: 10px;
}

.card-heading {
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-weight: $primary-font-weight;
}

.card-body {
  display: flex;
  justify-content: space-evenly;
  padding: 4px !important;
}

.card-body {
  display: flex;
  color: $train-status-color;
  font-weight: $primary-font-weight;
  font-size: small;
}

.card-body-danger-text {
  @extend .card-body;
}

.card-body-warning-text {
  @extend .card-body;
  justify-content: flex-end;
}

.center-div {
  background-color: $primary-border-right;
  width: 2px;
  height: 78px;
  margin-top: 6px;
}
