@import "../../../assets/bootstrap/font.scss";
@import "../../../assets/bootstrap/color.scss";

.lower-section {
  padding: 15px 0px 0px 20px;

  .defect-dropdown-row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
  }

  .heading-div {
    color: $black;
    font-size: 12px;
    font-weight: bold;
    padding: 0px 0px 5px 5px;
  }
  .margin-heading {
    font-size: 12px;
  }
  .space {
    margin-right: 10px;
    margin-left: 10px;
  }

  .margin-upload-button {
    margin-top: 20px;
    margin-right: 50px;
  }

  .comments-div {
    cursor: pointer;
    color: #1e5bce;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0;
    text-align: left;
  }

  .add-comment-div {
    margin-top: 20px;
    margin-bottom: 10px;

    input {
      margin-top: 5px;
      width: 91% !important;
      outline: 0 !important;
      box-shadow: none !important;
      border: none !important;
      border-bottom: 1px solid $black !important;
      border-radius: 0 !important;
      box-sizing: content-box;
      background-color: transparent !important;
      background: transparent !important;
    }
    .comment-error {
      font-size: 10px;
      color: $danger;
      margin-bottom: 0px !important;
    }
  }
}
