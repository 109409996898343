@import "../../assets/bootstrap/color.scss";
.outer {
  background-color: $primary-background-theme-fleet;
  padding: 5px;
  flex-grow: 1;
}
.contain {
  background-color: "inherit";
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.train-view {
  background-color: $white;
  margin-top: 10px;
}
.lower-view {
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  padding-bottom: 46px;
  background-color: $primary-background-theme-fleet;
}
.map-view {
  flex: 1;
  margin-left: 20px;
  margin-top: 10px;
  min-height: 100%;
}
