@import "../../../../assets/bootstrap/color.scss";
@import "../../../../assets/bootstrap/font.scss";

.asset-card {
  width: 445px;
  height: 300px;
  background-color: $white;
  margin-right: 8px;
  margin-left: 8px;
  box-shadow: 1px 1px 2px 1px $train-wagon-text;
  padding-left: 30px;
  padding-top: 10px;
  padding-right: 30px;
  .asset-box-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .asset-heading-text {
    font-size: 12px;
    color: #808895;
  }
  .asset-value-text {
    font-size: 12px;
    color: #343a40;
  }
  .card-div {
    width: 50%;
    margin-top: 10px;
  }
}
