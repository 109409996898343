@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.parent-tab-view {
  background-color: $white;
  padding-right: 10px;
  .nav-tabs {
    border-bottom: 0px;
    margin-top: 20px;
  }
  .parent-tab-pane {
    padding-top: 20px;
  }
  .nav-tabs .nav-link.active {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom-color: #495057 !important;
    font-family: Arial;
    font-weight: $bold-font-weight;
  }

  .nav-link {
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-top: 10px !important;
    border-bottom-color: $primary-background-theme-fleet !important;
    border-width: 2px !important;
    height: 40px !important;
    font-family: Arial;
  }
  .asset-container-cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: 14px;
  }
  a:hover {
    border-top-color: $white !important;
    border-left-color: $white !important;
    border-right-color: $white !important;
  }
  .asset-card {
    width: 445px;
    height: 300px;
    background-color: $white;
    margin-right: 8px;
    margin-left: 8px;
    box-shadow: 1px 1px 2px 1px $train-wagon-text;
    padding-left: 30px;
    padding-top: 10px;
    padding-right: 30px;
  }
  .asset-view {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    height: 330px;
  }
  .events-part {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: scroll;
  }
  .asset-parts {
    display: flex;
    flex-direction: row;
  }
  .heading-events {
    margin-bottom: 15px;
    font-size: $medium-heading-font-size;
    font-weight: $primary-font-weight;
    font: $primary-font-weight 16p $primary-font-family;
  }
  .trends-tab-pane {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
