@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.history-body {
  .history-body-container {
    background-color: $solitude;
    border-style: ridge;
    border-width: 1.5px;
    padding-left: 10px;

  }
}
