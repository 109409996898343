@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.carriage-container {
  .disable {
    opacity: 0.45;
  }
  .first-carriage {
    background-image: url("../../../assets/images/containerFirst.svg");
    height: 110px;
    width: 375px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 14px;
  }

  .middle-carriage {
    background-image: url("../../../assets/images/containerMiddle.png");
    height: 110px;
    width: 396px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 14px;
  }

  .last-carriage {
    background-image: url("../../../assets/images/containerEnd.png");
    height: 110px;
    width: 375px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 14px;
  }

  .vehicle-name-text {
    font-size: $micro-font-size;
    font-family: $primary-font-family;
    font-weight: $bold-font-weight;
    color: $light-slate-grey;
    padding-top: 15px;
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
  }

  .vehicle-name {
    font-size: $micro-font-size;
    font-weight: $bold-font-weight;
    color: $status-text;
  }
}
