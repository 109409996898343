@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";
.table-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.table-dropdown {
  display: flex;
  align-items: center;
  padding: 5px;
}

.text-right {
  font-size: 12px;
  font-weight: $primary-font-weight;
}
