@import "../../../../assets/bootstrap/color.scss";
@import "../../../../assets/bootstrap/font.scss";

.gear {
  width: 35px;
  height: 16px;
  position: absolute;
}
.front {
  .gear-absent {
    top: 52px;
    left: 12px;
    @extend .gear;
  }

  .gear-red {
    background-image: url("../../../../assets/images/redGearBox.svg");
    @extend .gear-absent;
  }

  .gear-amber {
    background-image: url("../../../../assets/images/amberGearBox.svg");
    @extend .gear-absent;
  }

  .gear-none {
    background-image: url("../../../../assets/images/greyGearBox.svg");
    @extend .gear-absent;
  }
}

.back {
  .gear-absent {
    top: 23px;
    left: 3px;
    @extend .gear;
  }

  .gear-red {
    background-image: url("../../../../assets/images/redGearBox.svg");
    @extend .gear-absent;
  }

  .gear-amber {
    background-image: url("../../../../assets/images/amberGearBox.svg");
    @extend .gear-absent;
  }

  .gear-none {
    background-image: url("../../../../assets/images/greyGearBox.svg");
    @extend .gear-absent;
  }
}

.disable {
  opacity: 0;
}
