@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.button-container {
  .btn {
    font-size: 12px !important;
    border-radius: 20px !important;
    font-weight: bold !important;
    font-family: $primary-font-family;
    padding: 3px 10px !important;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .border {
    border-radius: 3px !important;
  }

  .btn-secondary {
    background-color: $dark-grey !important;
    border-color: $dark-grey !important;
    color: $blue-charcol !important;
  }
}
