@import "../../../assets/bootstrap/color.scss";
@import "../../../assets/bootstrap/font.scss";

.first-bogie-first-wheelSet-powered {
  height: 90px;
  width: 75px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  bottom: 2px;
}

.carriage-first-bogie-front-wheelSet-powered {
  background-image: url("../../../assets/images/poweredFirstWheelSet.svg");
  @extend .first-bogie-first-wheelSet-powered;
}

.carriage-first-bogie-front-wheelSet-powered-light {
  background-image: url("../../../assets/images/poweredLightFirstWheelSet.svg");
  @extend .first-bogie-first-wheelSet-powered;
}

.first-bogie-second-wheelSet-powered {
  height: 90px;
  width: 75px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  bottom: 2px;
  left: 10px;
}

.carriage-first-bogie-back-wheelSet-powered {
  @extend .first-bogie-second-wheelSet-powered;
  background-image: url("../../../assets/images/poweredSecondWheelSet.svg");
  left: 10px;
}

.carriage-first-bogie-back-wheelSet-powered-light {
  @extend .first-bogie-second-wheelSet-powered;
  background-image: url("../../../assets/images/poweredLightSecondWheelSet.svg");
  left: 10px;
}

.carriage-first-bogie-back-wheelSet-powered-sharing {
  @extend .first-bogie-second-wheelSet-powered;
  background-image: url("../../../assets/images/poweredSecondWheelSet.svg");
  left: 90px;
}

.carriage-first-bogie-back-wheelSet-powered-sharing-light {
  @extend .first-bogie-second-wheelSet-powered;
  background-image: url("../../../assets/images/poweredLightSecondWheelSet.svg");
  left: 90px;
}

.first-bogie-first-wheelSet-non-powered {
  height: 90px;
  width: 75px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  bottom: 2px;
}
.carriage-first-bogie-front-wheelSet-non-powered {
  background-image: url("../../../assets/images/nonPoweredWheelSet.svg");
  @extend .first-bogie-first-wheelSet-non-powered;
}

.carriage-first-bogie-front-wheelSet-non-powered-light {
  background-image: url("../../../assets/images/nonPoweredLightWheelSet.svg");
  @extend .first-bogie-first-wheelSet-non-powered;
}

.first-bogie-second-wheelSet-non-powered {
  height: 90px;
  width: 75px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  bottom: 2px;
}

.carriage-first-bogie-back-wheelSet-non-powered {
  @extend .first-bogie-second-wheelSet-non-powered;
  background-image: url("../../../assets/images/nonPoweredBackWheelSet.svg");
  left: 15px;
}

.carriage-first-bogie-back-wheelSet-non-powered-light {
  @extend .first-bogie-second-wheelSet-non-powered;
  background-image: url("../../../assets/images/nonPoweredBackLightWheelSet.svg");
  left: 15px;
}

.carriage-first-bogie-back-wheelSet-non-powered-sharing {
  @extend .first-bogie-second-wheelSet-non-powered;
  background-image: url("../../../assets/images/nonPoweredBackWheelSet.svg");
  left: 90px;
}

.carriage-first-bogie-back-wheelSet-non-powered-sharing-light {
  @extend .first-bogie-second-wheelSet-non-powered;
  background-image: url("../../../assets/images/nonPoweredBackLightWheelSet.svg");
  left: 90px;
}
