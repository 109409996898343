@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.history-parent-container {
  .history-container {
    display: flex;
    margin: 5px;
  }

  .history-left-container {
    width: 19%;
    background-color: $white;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    min-width: 190px;
  }

  .history-graph-container {
    flex: 1;
    margin-left: 10px;
  }

  .history-accordion-container {
    background-color: $white;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
    margin: 10px;
    border-radius: 2px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .text-div {
    padding-left: 13px;
    font-size: 14px;
    font-family: arial;
    font-weight: $bold-font-weight;
    color: $status-text;
    padding-right: 75px;
  }

  .row-container {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .history {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid $primary-background-theme-fleet;
    border-top: 1px solid $primary-background-theme-fleet;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
  }

  .selected {
    background: $primary-theme-color !important;

    .text-dropdown,
    td {
      color: $white;
    }
  }

  .text-dropdown {
    padding-left: 13px;
    font-size: 12px;
    font-family: arial;

    color: $status-text;
    padding-right: 10px;
    padding-top: 5px;
  }

  .asset-timeline {
    background-color: $white;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
  .headline {
    padding: 10px;
    display: flex;
  }

  .heading-text {
    font-size: 12px;
    color: $blue-charcol;
    font-family: $primary-font-family;
    font-weight: $bold-font-weight;
  }
  .breadcrumb-container {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
  }

  hr {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-top: 1px solid #bfbfbf;
  }

  .dates {
    display: flex;
    justify-content: space-around;
    padding: 15px;
  }

  .hr-div {
    padding: 15px;
  }

  .dates-text {
    font-size: 12px;
    // color: #182026;
    font-family: arial;
  }
  .no-icon {
    padding-left: 15px;
  }

  .history-filter {
    margin: 10px;
  }

  .history-table-container {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  .table td {
    font-size: 12px;
    font-family: arial;
  }

  .no-content-image {
    svg {
      width: 340px;
    }
    text-align: center;
  }
}
.history-accordion-container {
  .icon-missing-div {
    padding-left: 15px;
  }
}
