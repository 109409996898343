@import "../../../assets/bootstrap/font.scss";
@import "../../../assets/bootstrap/color.scss";
.normalize {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: middle !important;
}
.display-danger {
  background-color: $danger;
}
.display-warning {
  background-color: yellow;
}

.display-normal {
  background-color: $train-status-color;
}

.center {
  padding-left: 5px;
}

.table-row-trains {
  height: 50px;
}

.side-highlighter {
  width: 2px;
  height: 50px;
}

.no-border {
  border-top: 0px !important;
}

.table-font-style {
  color: #656f7d;
  font-size: 12px;
  font-family: $primary-font-family;
}

.table-links {
  display: flex;
  font-size: 12px;
}

.a-link {
  margin-right: 5px;
}
a:hover {
  background-color: inherit;
}

.table-row-trains:hover {
  background-color: $dodger-blue;
  opacity: 0.8;
}

.table-row-trains:hover td > div {
  color: $white;
}

.table-row-trains:hover td > div > a {
  color: $white;
}
.table-row-trains:hover td > div > div {
  color: $white;
}

.text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.changeColor {
  background-color: $dodger-blue;
}

.changeColor td > div {
  color: $white;
}

.changeColor td > div > a {
  color: $white;
}
.changeColor td > div > div {
  color: $white;
}
