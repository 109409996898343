@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.dashboard-parent-container {
  .status-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // padding: 1.5rem;
    padding: 16px 32px 20px;
    flex-wrap: wrap;
    align-content: center;
    min-height: 114px;
  }

  .status {
    font-weight: $bold-font-weight;
    font-size: xx-large;
    color: $primary-status-heading;
    // margin-left: 30px;
    line-height: 1.3;
  }

  .trains-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .trains-container-total-trains {
    display: flex;
    flex-direction: row;
  }

  .trains-container-total-danger {
    border-right: 2px solid $primary-border-right;
  }

  .trains-container-total-warning {
    font-size: larger;
  }

  .right-arrow {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: $primary-font-weight;
    font-size: 24px;
  }
  .number-count-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .type {
    font-size: small;
    margin-bottom: 0;
    color: $train-status-color;
  }

  .type-icon {
    font-size: small;
    margin-bottom: 0;
    color: $train-status-color;
    align-self: self-start;
    margin-left: 17px;
  }
  .heading-status-numbers {
    font-weight: $primary-font-weight;
    margin-bottom: -0.5px !important;
  }

  .super {
    vertical-align: super;
    padding-left: 5px;

    color: $status-text;
    font-size: 14px;
  }

  .warning-icon {
    padding-left: 20px;
  }

  .demo {
    font-weight: $primary-font-weight;
    color: $status-text;
    font-size: $medium-font-size;
    line-height: 1.15;
    p {
      margin: inherit;
    }
  }

  .status-count-container {
    padding-right: 40px;
    padding-left: 40px;
  }
}
