@import "../../../assets/bootstrap/font.scss";
@import "../../../assets/bootstrap/color.scss";
.password-strength-meter {
  text-align: left;

  .password-strength-meter-progress {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
  }
  h5 {
    font-size: 14px;
  }
  .password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  .progress-bar {
    background-color: #eee;
  }

  .password-strength-meter-label {
    font-size: 12px;
  }

  .password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .strength-weak::-webkit-progress-value {
    background-color: $progress-theme-weak;
  }
  .strength-fair::-webkit-progress-value {
    background-color: $progress-theme-fair;
  }
  .strength-good::-webkit-progress-value {
    background-color: $progress-theme-good;
  }
  .strength-strong::-webkit-progress-value {
    background-color: $progress-theme-strong;
  }
}
