@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.parent-vehicle {
  display: flex;
  background-color: $primary-background-theme-fleet;
  flex-direction: column;
  height: 100%;
  .upper-section {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    padding-left: 10px;
    justify-content: space-between;
    align-items: center;
    font-size: $micro-font-size;
  }
  ol {
    margin-bottom: 0px;
  }

  .disabled-link:hover {
    background-color: inherit;
  }

  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex;
  }
  .vehicle-detail-lower-div {
    padding-bottom: 40px;
    background-color: $primary-background-theme-fleet;
    .col-lg-4 {
      padding-top: 20px;
      min-height: 100% !important;
    }
  }
  .col-lg-8 {
  }
  .tab-view {
    background-color: $white;
    padding-left: 10px;
    height: inherit;
    padding-bottom: 20px;
  }
  .col-lg-4 {
  }
  p {
    margin-bottom: 0px;
  }
  .dropdown-section {
    display: flex;
    flex-direction: row;
  }
  a:hover {
    background-color: inherit !important;
  }
  .dropdown-text {
    margin-right: 10px;
    padding-top: 10px;
    font: $primary-font-weight 16px $primary-font-family;
    color: $status-text;
  }
  .train-top-view {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 20px;
  }
  .train-top-view-text {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 15px;
    padding-top: 15px;
    font: $primary-font-weight 16px $primary-font-family;
    color: $status-text;
    background-color: $white;
  }
  .side-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .category-list-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
  }
  .category-list-text {
    font-size: 14px;
    font-weight: bold;
    color: $category-list-folder;
    margin-right: 10px;
    margin-left: 10px;
  }
}
