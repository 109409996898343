@import "../../assets/bootstrap/color.scss";
.form-container {
  min-width: 300px !important;
  max-width: 560px;
  min-height: 200px !important;
  padding: 30px;
  background-color: white;
  margin: 0 auto;
  border-radius: 5px;
  height: 400px;
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.form-section {
  display: flex;
  height: 100%;
  align-items: center;
}

@media only screen and (max-width: 650px) {
  .form-container {
    height: 350px;
  }
  .login-heading {
    font-size: 20px !important;
  }
  .login-button {
    margin-top: 35px !important;
  }
}
@media only screen and (max-width: 1010px) {
  .login-button {
    margin-top: 20px !important;
  }
  .login-heading {
    font-size: 25px !important;
    font-weight: 500;
    margin-top: 20px !important;
  }
}
.fullscreen {
  background: url(../../assets/images/backgroundLogin.png);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.logo {
  margin-top: 30px;
  margin-right: 30px;
  display: flex;
  align-self: flex-end;
}

.login-heading {
  color: $primary-theme-color;
  font-size: 30px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}
.login-button {
  width: 35%;
  min-width: 110px;
  margin-top: 40px;
  align-self: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  background-color: $primary-theme-color !important;
}
.login-footer {
  display: flex;
  background: transparent !important;
  color: $primary-theme-color !important;
  font-size: 15px;
  justify-content: center;
}

.input-form {
  width: 90% !important;
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid $primary-theme-color !important;
  border-radius: 0 !important;
  box-sizing: content-box;
  background-color: transparent !important;
  background: transparent !important;
}

.padding-style {
  margin-top: 35px !important;
}

.error-msg {
  color: $error-theme-color;
  font-size: 12px;
}
