@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";
.parent-custom-dropdown {
  .dropdown-menu {
    width: 250px;
  }
  .dropdown {
    background-color: inherit;
  }
  .dropdown-toggle {
    background-color: inherit !important;
    border-color: transparent !important;
    border-bottom-color: $dropdown-bottom-border-color !important;
    border-radius: 0px !important;
    color: $black !important;
  }

  a {
    background-color: inherit !important;
    font-size: $micro-font-size !important;
    font-weight: $least-font-weight !important;
    color: $text-color !important;
  }
}
