@import "../../assets/bootstrap/color.scss";
@import "../../assets/bootstrap/font.scss";

.asset-container {
  background-color: $white;
  box-shadow: 2px 2px 10px 2px $primary-card-shadow;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
}

.asset-container-dashboard {
  background-color: $white;
  padding: 10px;
  border-radius: 4px;
  max-width: 850px;
  min-width: 300px;
}

.asset-container-heading {
  font-size: 16px;
  font-weight: $bold-font-weight;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  font-family: $primary-font-family;
}

.asset-container-cards {
  display: flex;
  flex-wrap: wrap;
}
