@import "../../../../assets/bootstrap/color.scss";
@import "../../../../assets/bootstrap/font.scss";

.common-property {
  position: absolute;
}

.bearing {
  height: 9px;
  width: 21px;
  position: absolute;
}
.red-bearing {
  background-image: url("../../../../assets/images/redWheel.svg");
  @extend .bearing;
}
.amber-bearing {
  background-image: url("../../../../assets/images/yellowWheel.svg");
  @extend .bearing;
}
.none-bearing {
  background-image: url("../../../../assets/images/greyWheel.svg");
  @extend .bearing;
}

.wheel {
  height: 8px;
  width: 48px;
  position: absolute;
  background-repeat: no-repeat;
}
.red-wheel {
  @extend .wheel;
  background-image: url("../../../../assets/images/redBearing.svg");
}
.amber-wheel {
  background-image: url("../../../../assets/images/yellowBearing.svg");
  @extend .wheel;
}
.none-wheel {
  background-image: url("../../../../assets/images/greyBearing.svg");
  @extend .wheel;
}

.sensor {
  height: 20px;
  width: 30px;
  position: absolute;
  background-repeat: no-repeat;
}
.amber-sensor {
  background-image: url("../../../../assets/images/amberTopSensor.png");
  @extend .sensor;
}

.front-left {
  @extend .common-property;
  .front-left-bearing {
    left: 13px;
  }
  .bearing-red {
    @extend .red-bearing;
    @extend .front-left-bearing;
  }
  .bearing-amber {
    @extend .amber-bearing;
    @extend .front-left-bearing;
  }
  .bearing-none {
    @extend .none-bearing;
    @extend .front-left-bearing;
  }
  .front-left-wheel {
    top: 11px;
  }
  .wheel-red {
    @extend .red-wheel;
    @extend .front-left-wheel;
  }
  .wheel-amber {
    @extend .amber-wheel;
    @extend .front-left-wheel;
  }
  .wheel-none {
    @extend .none-wheel;
    @extend .front-left-wheel;
  }
  .front-left-sensor {
    top: -29px;
    left: 8px;
  }
  .sensor-amber {
    @extend .amber-sensor;
    @extend .front-left-sensor;
  }
  .sensor-none {
    @extend .sensor;
    @extend .front-left-sensor;
  }
}

.back-left {
  @extend .common-property;
  .back-left-bearing {
    left: 16px;
  }
  .bearing-red {
    @extend .red-bearing;
    @extend .back-left-bearing;
  }
  .bearing-none {
    @extend .none-bearing;
    @extend .back-left-bearing;
  }

  .bearing-amber {
    @extend .amber-bearing;
    @extend .back-left-bearing;
  }
  .back-left-wheel {
    top: 11px;
    left: 3px;
  }
  .wheel-red {
    @extend .red-wheel;
    @extend .back-left-wheel;
  }
  .wheel-amber {
    @extend .amber-wheel;
    @extend .back-left-wheel;
  }
  .wheel-none {
    @extend .none-wheel;
    @extend .back-left-wheel;
  }
  .back-left-sensor {
    top: -30px;
    left: 12px;
  }
  .sensor-amber {
    @extend .amber-sensor;
    @extend .back-left-sensor;
  }
  .sensor-none {
    @extend .sensor;
    @extend .back-left-sensor;
  }
}

.front-right {
  @extend .common-property;
  .front-right-bearing {
    top: 82px;
    left: 13px;
  }
  .bearing-red {
    @extend .red-bearing;
    @extend .front-right-bearing;
  }
  .bearing-amber {
    @extend .amber-bearing;
    @extend .front-right-bearing;
  }
  .bearing-none {
    @extend .none-bearing;
    @extend .front-right-bearing;
  }
  .front-right-wheel {
    transform: rotate(180deg);
    top: 73px;
    left: -1px;
  }
  .wheel-red {
    @extend .red-wheel;
    @extend .front-right-wheel;
  }
  .wheel-amber {
    @extend .amber-wheel;
    @extend .front-right-wheel;
  }
  .wheel-none {
    @extend .none-wheel;
    @extend .front-right-wheel;
  }
  .front-right-sensor {
    transform: rotate(180deg);
    top: 99px;
    left: 8px;
  }
  .sensor-amber {
    @extend .amber-sensor;
    @extend .front-right-sensor;
  }
  .sensor-none {
    @extend .sensor;
    @extend .front-right-sensor;
  }
}
.back-right {
  @extend .common-property;
  .back-right-bearing {
    left: 16px;
    top: 82px;
  }
  .bearing-red {
    @extend .red-bearing;
    @extend .back-right-bearing;
  }
  .bearing-amber {
    @extend .amber-bearing;
    @extend .back-right-bearing;
  }
  .bearing-none {
    @extend .none-bearing;
    @extend .back-right-bearing;
  }

  .back-right-wheel {
    transform: rotate(180deg);
    top: 73px;
    left: 3px;
  }
  .wheel-red {
    @extend .red-wheel;
    @extend .back-right-wheel;
  }
  .wheel-amber {
    @extend .amber-wheel;
    @extend .back-right-wheel;
  }
  .wheel-none {
    @extend .none-wheel;
    @extend .back-right-wheel;
  }
  .back-right-sensor {
    transform: rotate(180deg);
    top: 98px;
    left: 11px;
  }
  .sensor-amber {
    @extend .amber-sensor;
    @extend .back-right-sensor;
  }
  .sensor-none {
    @extend .sensor;
    @extend .back-right-sensor;
  }
}

.disable {
  opacity: 0;
}
